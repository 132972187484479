import * as React from "react";
import Layout from "../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

const AboutPage = () => {
  return (
    <Layout>
      <div className="card full-width">
        <div className="card-content">
          <h1 className="title" id="title">
            Satzung
          </h1>
          <form
            style={{ display: "inline" }}
            action="/static/Satzung2018.pdf"
            method="get"
          >
            <button className="button is-link is-large is-fullwidth mb-4">
              <span className="icon">
                <FontAwesomeIcon icon={faFileDownload} />
              </span>
              <span>Download Satzung</span>
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
